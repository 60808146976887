<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.store" />

    <div v-else>
      <b-card class="sticky-header-control">
        <b-row>
          <b-col class="d-flex align-items-center" md="8">
            <h3 class="text-primary m-0 p-0">
              {{ $t("g.addMaintenanceType") }} |
            </h3>
            <b-breadcrumb :items="breadCrumbItems" />
          </b-col>
          <b-col md="4" class="justify-content-end d-flex">
            <b-button
              @click.prevent="validationForm"
              type="submit"
              variant="primary"
              >{{ $t("g.submit") }}</b-button
            >
          </b-col>
        </b-row>
      </b-card>
      <validation-observer ref="maintenanceTypeForm">
        <b-form>
          <b-card :title="$t('g.basicInformations')">
            <b-row>
              <b-col md="4">
                <b-form-group :label="$t('g.maintenanceTypeName')">
                  <validation-provider
                    #default="{ errors }"
                    name="maintenance type name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form_data.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t('g.placeholder.EnterTheMaintenanceTypeNameHere')
                      "
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('g.status')">
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form_data.is_available"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="available_options"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('g.maintenanceType')">
                  <validation-provider
                    #default="{ errors }"
                    name="country of origin"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form_data.is_cycle"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="cycle_options"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" v-if="form_data.is_cycle">
                <!-- <b-form-group :label="$t('g.Input to maintenance after ') + form_data.days_count + $t('g.day')"> -->
                <b-form-group
                  :label="
                    $t('g.Entering maintenance (every ') +
                    form_data.days_count +
                    $t('g.day') +
                    ')'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="days count"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      v-model="form_data.days_count"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t('g.placeholder.Enter the duration in days')
                      "
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- language update -->
              <b-col md="4" v-if="isEdit">
                <b-form-group :label="$t('g.language')" label-for="Language">
                  <validation-provider
                    #default="{ errors }"
                    name="Language"
                    rules="required"
                  >
                    <select class="form-control" v-model="form_data.lang">
                      <option selected value>
                        {{ $t("g.Please_Select_Language") }}
                      </option>
                      <option
                        v-for="(lang, index) in lang_options"
                        :key="index"
                        :value="lang.value"
                      >
                        {{ $t(`g.${lang.text}`) }}
                      </option>
                    </select>
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormDatepicker,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BBreadcrumb,
  BCardHeader,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import store from "@/store/index";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    BCard,
    ContentNotView,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    Loading,
    BForm,
    ValidationObserver,
    BFormInput,
    BButton,
    BBreadcrumb,
    Error,
    BFormSelect,
  },
  data() {
    return {
      form_data: {
        name: null,
        is_available: false,
        is_cycle: false,
        days_count: "",
        lang: null,
      },
      current_id: null,
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      available_options: [
        {
          value: null,
          text: this.$t("g.Please_Select_Status"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.available"),
        },
        {
          value: 0,
          text: this.$t("g.notAvailable"),
        },
      ],
      cycle_options: [
        {
          value: null,
          text: this.$t("g.Please_Select_Maintenance_Type"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.Periodic"),
        },
        {
          value: 0,
          text: this.$t("g.Exceptional"),
        },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },
        {
          text: this.$t("g.maintenanceTypesList"),
          to: { name: "maintenanceTypesList" },
        },
        {
          text: this.$t("g.addMaintenanceType"),
          active: true,
        },
      ],
      isLoading: true,
      isEdit: null,
      isError: null,
      subPermission: {},
    };
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.$http
        .get(`admin/maintenanceTypes/${this.current_id}`)
        .then((res) => {
          for (const key in res.data.data) {
            if (key === "is_available" || key === "is_cycle") {
              this.form_data[key] = Number(res.data.data[key]);
            } else if (key === "name") {
              this.form_data[key] = res.data.data[key];
            }
          }
          // console.log(this.form_data);

          this.isEdit = true;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "maintenanceTypes");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = true;
          this.isEdit = false;
        });
    } else {
      this.isEdit = false;
      setTimeout(() => {
        this.isLoading = false;
        this.isError = false;
        store.dispatch("GET_PERMISSION", "maintenanceTypes");
        this.subPermission = store.state.permissions.sub;
      }, 2000);
    }
  },
  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "date") {
          form[i] = new Date();
        }
      }
    },
    validationForm() {
      if (this.form_data.days_count === "") {
        delete this.form_data.days_count;
      }
      this.$refs.maintenanceTypeForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item]);
          }
          if (this.isEdit) {
            console.log(this.current_id);
            this.$http
              .post(`admin/maintenanceTypes/${this.current_id}`, formData, {
                params: {
                  _method: "put",
                },
              })
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );

                setTimeout(() => {
                  this.$router.push({ name: "maintenanceTypesList" });
                });
                this.clearForm();
                this.$refs.maintenanceTypeForm.reset();
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.makeToast(
                    "danger",
                    err.response.data.errors[key][0],
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          } else {
            this.$http
              .post("admin/maintenanceTypes", formData)
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );

                setTimeout(() => {
                  this.$router.push({ name: "maintenanceTypesList" });
                });
                this.clearForm();
                this.$refs.maintenanceTypeForm.reset();
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.makeToast(
                    "danger",
                    err.response.data.errors[key][0],
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          }
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>
